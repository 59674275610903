<template>
  <CButton :color="buttonColor()" class="btn-block" type="submit" :disabled="vld.$invalid">
    {{label}}
  </CButton>
</template>

<script>
export default {
  name: 'formelements-submit',
  components: {
  },
  props: {
    label: {
      default: 'Absenden',
      type: String
    },
    vld: {
      type: Object,
      required: true
    }
  },
  methods: {
    buttonColor () {
      if (this.vld.$invalid) {
        return 'danger'
      } else {
        return 'success'
      }
    }
  }
}
</script>
